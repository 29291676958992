import { useNotificationList } from "architecture/src/shared/api/endpoints/notification/notification";

export const useNotificationsUnreadTotal = () => {
  const { data: notifications } = useNotificationList(
    {},
    {
      query: {
        select: (notifications) => notifications.meta.totalRecords,
      },
    },
  );
  return notifications || 0;
};
