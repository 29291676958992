import { useAuthProfileListSuspense as useAuthProfileList } from "architecture/src/shared/api/endpoints/auth/auth";

export const useProfileCityId = (): string | undefined => {
  const { data: stringCity } = useAuthProfileList({
    query: {
      select: (profile) => profile.city?.toString(),
    },
  });

  return stringCity;
};
