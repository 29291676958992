import { useMotionValueEvent, useScroll } from "framer-motion";
import { useState } from "react";

export const useScrollVisibility = () => {
  const { scrollY } = useScroll();

  const [hidden, setHidden] = useState(false);
  const [lastYPos, setLastYPos] = useState(0);

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (latest > lastYPos && !hidden) {
      setHidden(true);
    } else if (latest < lastYPos && hidden) {
      setHidden(false);
    }
    setLastYPos(latest);
  });

  const transition = { duration: 0.2 };

  return { hidden, transition };
};
