/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Dapper API
 * Public Documentation Dapper
 * OpenAPI spec version: v1
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type { Error } from "../../../domain/models/error";
import type { NotificationList200 } from "../../../domain/models/notificationList200";
import type { NotificationListDetail } from "../../../domain/models/notificationListDetail";
import type { NotificationListParams } from "../../../domain/models/notificationListParams";
import type { NotificationResponseData } from "../../../domain/models/notificationResponseData";
import { apiClient } from "../../../../lib/api-client/api-client";
import type { ErrorType } from "../../../../lib/api-client/api-client";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const notificationList = (
  params?: NotificationListParams,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<NotificationList200>(
    { url: "/notification/", method: "GET", params },
    options,
  );
};

export const getNotificationListQueryKey = (
  params?: NotificationListParams,
) => {
  return ["/notification/", ...(params ? [params] : [])] as const;
};

export const getNotificationListInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof notificationList>>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationListParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof notificationList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationList>>
  > = () => notificationList(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof notificationList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationListInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationList>>
>;
export type NotificationListInfiniteQueryError = ErrorType<unknown>;

export const useNotificationListInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof notificationList>>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationListParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof notificationList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationListInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNotificationListQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationList>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof notificationList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationList>>
  > = () => notificationList(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationListQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationList>>
>;
export type NotificationListQueryError = ErrorType<unknown>;

export const useNotificationList = <
  TData = Awaited<ReturnType<typeof notificationList>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationListParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof notificationList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNotificationListSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationList>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationListParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof notificationList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getNotificationListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationList>>
  > = () => notificationList(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof notificationList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationListSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationList>>
>;
export type NotificationListSuspenseQueryError = ErrorType<unknown>;

export const useNotificationListSuspense = <
  TData = Awaited<ReturnType<typeof notificationList>>,
  TError = ErrorType<unknown>,
>(
  params?: NotificationListParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof notificationList>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationListSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const notificationReadAllPartialUpdate = (
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<unknown>(
    { url: "/notification/read-all", method: "PATCH" },
    options,
  );
};

export const getNotificationReadAllPartialUpdateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationReadAllPartialUpdate>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationReadAllPartialUpdate>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationReadAllPartialUpdate>>,
    void
  > = () => {
    return notificationReadAllPartialUpdate(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationReadAllPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationReadAllPartialUpdate>>
>;

export type NotificationReadAllPartialUpdateMutationError = ErrorType<Error>;

export const useNotificationReadAllPartialUpdate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationReadAllPartialUpdate>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions =
    getNotificationReadAllPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const notificationPartialUpdate = (
  id: string,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<NotificationListDetail>(
    { url: `/notification/${id}`, method: "PATCH" },
    options,
  );
};

export const getNotificationPartialUpdateMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationPartialUpdate>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationPartialUpdate>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationPartialUpdate>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return notificationPartialUpdate(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationPartialUpdate>>
>;

export type NotificationPartialUpdateMutationError = ErrorType<Error>;

export const useNotificationPartialUpdate = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationPartialUpdate>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof apiClient>;
}) => {
  const mutationOptions = getNotificationPartialUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const notificationRead = (
  id: number,
  options?: SecondParameter<typeof apiClient>,
  signal?: AbortSignal,
) => {
  return apiClient<NotificationResponseData>(
    { url: `/notification/${id}/`, method: "GET", signal },
    options,
  );
};

export const getNotificationReadQueryKey = (id: number) => {
  return [`/notification/${id}/`] as const;
};

export const getNotificationReadQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationRead>>,
  TError = ErrorType<Error>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof notificationRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNotificationReadQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationRead>>
  > = ({ signal }) => notificationRead(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationRead>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationReadQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationRead>>
>;
export type NotificationReadQueryError = ErrorType<Error>;

export const useNotificationRead = <
  TData = Awaited<ReturnType<typeof notificationRead>>,
  TError = ErrorType<Error>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof notificationRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationReadQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNotificationReadSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof notificationRead>>,
  TError = ErrorType<Error>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof notificationRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getNotificationReadQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationRead>>
  > = ({ signal }) => notificationRead(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof notificationRead>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type NotificationReadSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof notificationRead>>
>;
export type NotificationReadSuspenseQueryError = ErrorType<Error>;

export const useNotificationReadSuspense = <
  TData = Awaited<ReturnType<typeof notificationRead>>,
  TError = ErrorType<Error>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof notificationRead>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof apiClient>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getNotificationReadSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
