import { useMediaQuery } from "@mantine/hooks";

export const useMediaIsMobile = () => useMediaQuery("(max-width: 767px)");

export const useMediaIsTablet = () => useMediaQuery("(min-width: 768px)");

export const useMediaIsDesktop = () => useMediaQuery("(min-width: 1025px)");

export const useMediaHasAnimations = () => useMediaIsTablet();

export default useMediaQuery;
